<template>
  <div class="page">
    <Backlink :backlink="prevRoute" />
    <div class="content">
      <div class="content__container">
        <TextBlock withTitle :id="1" />
        <div class="center">
          <router-link :to="prevRoute" class="button button__form">{{ "continue" | localize }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import TextBlock from '@/components/Text.vue'
import { commonMixin } from '@/mixins/commonMixin.js'

export default {
  name: 'Terms_of_use',
  components: { Backlink, TextBlock },
  mixins: [commonMixin],
  props: {
    routeName: { type: String, default: '/' },
    returnToLogin: { type: Boolean, default: false }
  },
  mounted () {
    if (this.returnToLogin && this.routeName) {
      this.prevRoute = { name: this.routeName, params: { returnToLogin: true } }
    }
  }
}
</script>
